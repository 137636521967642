<template>
  <main class="index-page">
    <section class="manual">
      <h1>
        Расскажем как посмотреть турецкое кино онлайн одновременно на расстоянии вместе с друзьями
      </h1>
      <p class="desc">
        <strong>Cмотреть любимое турецкое кино онлайн вместе с другом на расстоянии</strong> реально у нас!
        Несколько легких шагов по созданию комнаты и 1 клик для приглашения друга, и это все!
        Время таймлайна всех участников синхронизируется автоматически с создателем комнаты.
        В то же время каждый из зрителей может самостоятельно управлять плеером: смена времени, воспроизведение, пауза, громкость и качество видео.
      </p>
      <ul class="table">
        <li class="item">
          <!-- <figure v-if="isDesktop">
            <figcaption>
              1) <strong>Ищем аниме</strong>, которое хотим посмотреть
            </figcaption>
            <a href="/static_files/cinema/1.jpeg" target="_blank">
              <img :data-src="'/static_files/cinema/1.jpeg'" src="/static_files/no-poster.png" class="lazy lazyload" alt="Создание комнаты для совместного просмотра - изображение 1" />
            </a>
          </figure> -->
          <div>
            <span class="fig">1) <strong>Ищем кино</strong>, которое хотим посмотреть</span>
            <Search :type="`rooms`" />
          </div>
        </li>
        <li class="item">
          <figure>
            <figcaption>
              2) <strong>Выбираем из выпадающего списка кино,</strong> которое нам подходит
            </figcaption>
            <a href="/static_files/cinema/2.jpeg" target="_blank">
              <img :data-src="'/static_files/cinema/2.jpeg?8'" src="/static_files/no-poster.png" class="lazy lazyload" alt="Создание комнаты для совместного просмотра - изображение 2" />
            </a>
          </figure>
        </li>
        <li class="item">
          <figure>
            <figcaption>
              3) Вот и все! Создание комнаты завершено. Теперь самое главное - приглашаем друга!
            </figcaption>
            <a href="/static_files/cinema/3.jpg" target="_blank">
              <img :data-src="'/static_files/cinema/3.jpg?1'" src="/static_files/no-poster.png" class="lazy lazyload" alt="Создание комнаты для совместного просмотра - изображение 3" />
            </a>
          </figure>
        </li>
      </ul>

      <section class="index-desc">
        <h2>Смотрите онлайн лучшие турецкие сериалы и фильмы</h2>

        <figure>
          <img src="/static_files/no-poster.png" :data-src="isDesktop ? `/static_files/index-page.jpg?10` : `/static_files/index-page_mobile.jpg?10` " class="lazy lazyload" alt="Совместный просмотр турецких сериалов вместе с другом на расстоянии">
          <figcaption>Отборное турецкое кино у нас</figcaption>
        </figure>

        <div>
          <p>Добро пожаловать на наш сайт, посвященный турецкому кино и турецким сериалам! Мы рады представить вам уникальную возможность наслаждаться просмотром самых популярных и захватывающих турецких фильмов и сериалов прямо онлайн.</p>

          <p>У нас вы найдете огромную коллекцию турецких фильмов и сериалов на русском языке. Вам больше не нужно тратить время на поиск и загрузку, все доступно бесплатно и в лучшем качестве. Наша библиотека постоянно обновляется, чтобы предложить вам только самые свежие и популярные произведения турецкой кинематографии.</p>

          <p>Погрузитесь в захватывающие сюжеты и интригующие истории, воплощенные на экране мастерами турецкого кино. Вас ожидают различные жанры: романтика, драма, комедия, триллеры и многие другие, чтобы удовлетворить самые разнообразные вкусы зрителей.</p>

          <p>Наши видео предоставляются в высоком разрешении, чтобы вы могли наслаждаться каждой деталью и ощущением присутствия. Благодаря удобному интерфейсу сайта, вы сможете легко найти интересующие вас фильмы и сериалы и начать их просмотр в любое удобное время.</p>

          <p>Насладитесь историей, культурой и талантом турецкого кино вместе с нами. Вдохновляйтесь удивительными персонажами и событиями, которые покажут вам разнообразие и богатство турецкой культуры. Присоединяйтесь к нам и открывайте захватывающий мир турецких фильмов и сериалов прямо сейчас!</p>

          <p>Не упустите шанс окунуться в мир турецкой кинематографии. Смотрите онлайн бесплатно и на русском языке прямо сейчас!</p>
        </div>
      </section>
    </section>
  </main>
</template>

<script>
import { mapState } from 'vuex'

export default {
  components: {
    Search: () => import('~/components/Search.vue'),
  },
  computed: mapState(['isDesktop']),
}
</script>

<style src="~/assets/index.styl" lang="stylus" />
